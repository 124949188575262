import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "pretitle": "Appearance",
  "title": "Design Details 196",
  "date": "2017-05-03T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1100px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "48.72727272727273%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECA//EABcBAAMBAAAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAUs4rCyAf//EABcQAQEBAQAAAAAAAAAAAAAAAAEAESD/2gAIAQEAAQUClLeP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFxAAAwEAAAAAAAAAAAAAAAAAAAEgIf/aAAgBAQAGPwIxz//EABgQAQEAAwAAAAAAAAAAAAAAAAEQABEx/9oACAEBAAE/IdmFOjLf/9oADAMBAAIAAwAAABBID//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPxCRj//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABkQAQADAQEAAAAAAAAAAAAAAAEAESEQMf/aAAgBAQABPxDQFqVJLtyiKuOPvP/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "design details",
          "title": "design details",
          "src": "/static/ac3482f6cb9528ef9dea1febe18c02f1/ddced/design-details.jpg",
          "srcSet": ["/static/ac3482f6cb9528ef9dea1febe18c02f1/35f54/design-details.jpg 275w", "/static/ac3482f6cb9528ef9dea1febe18c02f1/d7854/design-details.jpg 550w", "/static/ac3482f6cb9528ef9dea1febe18c02f1/ddced/design-details.jpg 1100w", "/static/ac3482f6cb9528ef9dea1febe18c02f1/670dc/design-details.jpg 1650w", "/static/ac3482f6cb9528ef9dea1febe18c02f1/7645c/design-details.jpg 1922w"],
          "sizes": "(max-width: 1100px) 100vw, 1100px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`This week was very special. While in San Francisco, Rafa and I not only met in person for the first time, but we also got to record an episode of Design Details with our friends Bryn and Brian. I really admire what they’ve been able to do with Spec, so it was a real honor to be a guest on their show. In this episode we talk about podcasting, side projects, building design systems, design obsession, and so much more.`}</p>
    <p><a parentName="p" {...{
        "href": "https://spec.fm/podcasts/design-details/68532"
      }}>{`Give it a listen on Spec`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      